import { useRef, useState } from 'react'
import { memo } from 'react'

import { useToast } from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import axios from 'axios'
import * as yup from 'yup'

import { Form } from '../../components/Form/Form'
import { Input } from '../../components/Form/Input'
import { Select } from '../../components/Form/Select'
import { FormContainer } from '../../components/FormContainer'
import { useAuth } from '../../hooks/useAuth'
import { api } from '../../services/api'
import { formatYupError } from '../../utils/formatYupError'
import { createFormSchema } from './validationSchema'
type FormData = {
  fullName: string
  email: string
  master: string | boolean
}

const options = [
  {
    label: 'Admin',
    value: 'admin'
  },
  {
    label: 'Master',
    value: 'master'
  }
]

type CreateAdminProps = {
  onSubmit?: () => void
}

export function CreateAdminRaw({ onSubmit }: CreateAdminProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false)
  const { user } = useAuth()
  const formRef = useRef<FormHandles>(null)
  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  })

  const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
    setLoading(true)
    data.master = data.master === 'master'
    try {
      await createFormSchema.validate(data, { abortEarly: false })
      await api.post(`admin/settings/admins`, data)
      onSubmit && onSubmit()
      reset()
      toast({
        status: 'success',
        title: 'Sucesso!',
        description: 'Administrador criado com sucesso!'
      })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else if (axios.isAxiosError(error)) {
        toast({
          status: 'error',
          title: 'Erro!',
          description: 'Ocorreu um erro ao tentar cadastrar.'
        })
      }
    } finally {
      setLoading(false)
    }
  }
  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormContainer
        buttonIsDisabled={!user?.master}
        headerText="Novo Administrador"
        buttonText="Cadastrar"
        loading={loading}
      >
        <Input
          isDisabled={!user?.master}
          name="fullName"
          label="Nome Completo (obrigatório)"
          placeholder="Informe seu nome completo"
          mb="4"
        />
        <Input
          isDisabled={!user?.master}
          name="email"
          label="Email (obrigatório)"
          placeholder="Informe seu email"
          mb="4"
        />
        <Select
          isDisabled={!user?.master}
          options={options}
          name="master"
          label="Nível de Permissão (obrigatório)"
        />
      </FormContainer>
    </Form>
  )
}

export const CreateAdmin = memo(CreateAdminRaw)
