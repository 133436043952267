import { Box, Divider, Flex, Skeleton } from '@chakra-ui/react'

export function PlanSkeleton(): JSX.Element {
  return (
    <Box
      d={'flex'}
      flexDirection={'column'}
      padding="6"
      boxShadow="md"
      bg="white"
      borderRadius="6"
      mb={5}
    >
      <Skeleton height="25px" w={150} />
      <Divider my="4" />
      <Skeleton height="10px" w={20} mb={4} mt={2} />
      <Skeleton height="25px" w={'full'} mb={8} mt={2} />
      <Skeleton height="10px" w={20} mb={4} mt={2} />
      <Skeleton height="25px" w={'full'} mb={8} mt={2} />
      <Flex
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mt={2}
        mb={7}
      >
        <Skeleton height="10px" w={150} />
        <Skeleton height="20px" w={25} />
      </Flex>
      <Skeleton height="25px" alignSelf={'flex-end'} w={100} mt={2} />
    </Box>
  )
}
