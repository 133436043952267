import { Redirect, Route, Switch } from 'react-router-dom'

import { PageTemplate } from '../components/PageTemplate'
import { Assessments } from '../pages/assessments'
import { Home } from '../pages/home'
import { Lawyers } from '../pages/lawyers'
import { Plans } from '../pages/plans'
import { Services } from '../pages/services'
import { Settings } from '../pages/Settings/'
import { Specialities } from '../pages/specialities'

export function RoutesWithAuth(): JSX.Element {
  return (
    <PageTemplate>
      <Switch>
        <Route path="/home" component={Home} exact />
        <Route path="/plans" component={Plans} exact />
        <Route path="/lawyers" component={Lawyers} exact />
        <Route path="/specialities" component={Specialities} exact />
        <Route path="/services" component={Services} exact />
        <Route path="/assessments" component={Assessments} exact />
        <Route path="/settings" component={Settings} exact />
        <Redirect to="/home" />
      </Switch>
    </PageTemplate>
  )
}
