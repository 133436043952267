import { useEffect, useRef } from 'react'
import { IoAlertCircleOutline } from 'react-icons/io5'

import {
  SwitchProps as ChakraSwitchProps,
  Switch as ChakraSwitch,
  theme,
  FormControl,
  FormLabel,
  IconButton,
  Icon
} from '@chakra-ui/react'
import { useField } from '@unform/core'

import { Tooltip } from '../Tooltip'

type InputProps = ChakraSwitchProps & {
  name: string
  label?: string
}

export function Switch({ name, label, ...rest }: InputProps): JSX.Element {
  const switchRef = useRef<HTMLInputElement>(null)
  const { defaultValue, error, fieldName, registerField, clearError } =
    useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: switchRef.current,
      path: 'checked'
    })
  }, [fieldName, registerField])

  return (
    <FormControl
      isInvalid={!!error}
      display="flex"
      alignItems={'start'}
      justifyContent={'space-between'}
    >
      {label && <FormLabel textAlign={'center'}>{label}</FormLabel>}
      <ChakraSwitch
        defaultChecked={defaultValue}
        ref={switchRef}
        colorScheme="green"
        onFocus={clearError}
        _focus={{
          boxShadow: `0 0 0 1px ${theme.colors.yellow['300']}`,
          borderColor: 'yellow.300'
        }}
        {...rest}
      />
      {!!error && (
        <Tooltip
          hasArrow
          arrowSize={15}
          label={error}
          px="2"
          py="1"
          placement="top-end"
          aria-label="Error tooltip"
        >
          <IconButton
            d="flex"
            icon={<Icon as={IoAlertCircleOutline} />}
            fontSize="22"
            variant="unstyled"
            aria-label="Open error message"
            color="red.500"
          />
        </Tooltip>
      )}
    </FormControl>
  )
}
