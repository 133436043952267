import {
  Button,
  Text,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps
} from '@chakra-ui/react'

type ConfirmationModalProps = Omit<ModalProps, 'children'> & {
  title: string
  onAccept: () => void
  isConfirmation?: boolean
}

export function ConfirmationModal({
  title,
  onClose,
  onAccept,
  isConfirmation,
  ...rest
}: ConfirmationModalProps): JSX.Element {
  return (
    <Modal onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent my="auto" mx="4">
        <ModalBody py="4">
          <Heading fontWeight="medium" fontSize="2xl" mb="2">
            {title}
          </Heading>
          <Text>Você tem certeza? Essa ação não pode ser revertida.</Text>
          <HStack w="min" mt="6" ml="auto" spacing="4">
            <Button colorScheme="gray" onClick={onClose}>
              Cancelar
            </Button>
            <Button colorScheme="red" onClick={onAccept}>
              {isConfirmation ? 'Aceitar' : 'Excluir'}
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
