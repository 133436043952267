import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { AxiosRequestConfig } from 'axios'

import { api } from '../services/api'

export type Rating = {
  id: string
  comment: string
  response: string
  rating: number
  createdAt: string
  customer: string
  email: string
  oab: string
  lawyer: string
}

type GetRatingsProps = {
  page?: number
  per_page?: number
  filter?: string
}

export type GetRatingsResponse = {
  totalCount: number
  ratings: Rating[]
}

export async function getRatings(
  { page, per_page, filter }: GetRatingsProps = {},
  config?: AxiosRequestConfig
): Promise<GetRatingsResponse> {
  const { data, headers } = await api.get('/admin/ratings', {
    params: {
      page: page,
      limit: per_page,
      ...(filter ? { term: filter } : {})
    },
    ...config
  })
  const totalCount = Number(headers['x-total-count'] ?? 0)

  return { ratings: data, totalCount }
}

export function useRatings(
  { page, per_page, filter }: GetRatingsProps = {},
  options?: UseQueryOptions<GetRatingsResponse>
): UseQueryResult<GetRatingsResponse> {
  return useQuery<GetRatingsResponse>(
    ['ratings', page, per_page, filter],
    () => getRatings({ page, per_page, filter }),
    {
      staleTime: 1000 * 10, // Ten seconds
      ...options
    }
  )
}
