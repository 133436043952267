import { formatPrice } from '../../utils/formatPrice'

type configProps = {
  name: string
}

type SeriesObjectProps = {
  seriesIndex: number
  w: {
    config: {
      series: configProps[]
    }
  }
}

type SeriesProps = {
  type?: string
  name?: string
  data: number[] | string[]
}

export type ChartProps = {
  options: {
    chart: {
      id: string
    }
    xaxis: {
      categories: string[] | number[]
      labels?: {
        show?: boolean
        style?: {
          fontSize: string
        }
      }
    }
  }
  series?: SeriesProps[] | undefined
}

export const configOptions = {
  chart: {
    id: 'basic-bar'
  },
  colors: ['#3BD8B2'],
  xaxis: {
    categories: ['', '', '', '', '', '', '']
  },
  plotOptions: {
    bar: {
      dataLabels: {
        position: 'top'
      }
    }
  },
  dataLabels: {
    show: true,
    offsetY: -25,
    style: {
      fontSize: '0',
      colors: ['#304758']
    },
    formatter(
      value: number,
      { seriesIndex, w }: SeriesObjectProps
    ): string | number {
      const isSales = w.config.series[seriesIndex].name === 'Valor'

      if (isSales) {
        return formatPrice(value)
      }
      return value
    }
  },
  tooltip: {
    shared: false,
    y: {
      formatter(
        value: number,
        { seriesIndex, w }: SeriesObjectProps
      ): string | number {
        const isSales = w.config.series[seriesIndex].name === 'Valor'

        if (isSales) {
          return formatPrice(value)
        }
        return value
      }
    }
  }
}
