import { ChangeEvent, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Flex,
  Heading,
  Select,
  SimpleGrid,
  Spinner,
  useBreakpointValue,
  Text,
  useToast
} from '@chakra-ui/react'

import ChartView, { GraphicProps } from '../components/ChartView'
import { ChartViewSkeleton } from '../components/ChartViewSkeleton'
import { CardProps, SmallCard } from '../components/SmallCard'
import { SmallCardSkeleton } from '../components/SmallCardSkeleton'
import { useAuth } from '../hooks/useAuth'
import { api } from '../services/api'

const filterOptions = [
  {
    label: 'Últimos 7 dias',
    value: 7
  },
  {
    label: 'Últimos 15 dias',
    value: 15
  },
  {
    label: 'Últimos 30 dias',
    value: 30
  },
  {
    label: 'Últimos 60 dias',
    value: 60
  },
  {
    label: 'Últimos 90 dias',
    value: 90
  },
  {
    label: 'Ultimo Ano',
    value: 365
  }
]

export function Home(): JSX.Element {
  const [filterPeriod, setFilterPeriod] = useState<string>('7')
  const [cardValues, setCardValues] = useState<CardProps[]>()
  const [specialtyValue, setSpacialtyValue] = useState<GraphicProps>()
  const [serviceValue, setServiceValue] = useState<GraphicProps>()
  const [cityValue, setCityValue] = useState<GraphicProps>()
  const [revenueValue, setRevenueValue] = useState<GraphicProps>()
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { user } = useAuth()
  const toast = useToast({
    position: 'top-right',
    duration: 3000,
    isClosable: true
  })

  const isWideVersion = useBreakpointValue({
    base: false,
    xl: true
  })

  useEffect(() => {
    async function fetchData(): Promise<void> {
      try {
        setLoading(true)
        const [card, specialty, city, service, revenue] = await Promise.all([
          api.get(`admin/dashboard/cards/${filterPeriod}`),
          api.get(`admin/dashboard/specialty/${filterPeriod}`),
          api.get(`admin/dashboard/city/${filterPeriod}`),
          api.get(`admin/dashboard/service/${filterPeriod}`),
          api.get(`admin/dashboard/revenue/${filterPeriod}`)
        ])
        setCardValues(card.data)
        setSpacialtyValue(specialty.data)
        setServiceValue(service.data)
        setCityValue(city.data)
        setRevenueValue(revenue.data)
      } catch (error) {
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [filterPeriod])

  function handlePeriodChange(event: ChangeEvent<HTMLSelectElement>): void {
    return setFilterPeriod(event.target.value)
  }

  async function handleExportCsv(): Promise<void> {
    try {
      const { data } = await api.get('admin/dashboard/csv')
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' })
      const csvURL = window.URL.createObjectURL(blob)
      window.open(csvURL)
    } catch (error) {
      toast({
        status: 'error',
        title: 'Erro!',
        description: 'Erro ao exportar!'
      })
    }
  }

  return (
    <Flex h="min-content" w="full" p="30" d="flex" direction="column">
      <Box d="flex" justifyContent="space-between" w="full">
        <Heading
          fontWeight={400}
          fontSize={22}
        >{`Olá ${user?.fullName}, seja bem vindo(a)!`}</Heading>
        <Button
          colorScheme="yellow"
          minW={[105, 120]}
          onClick={handleExportCsv}
        >
          Exportar CSV
        </Button>
      </Box>
      <Flex w={240} m="16px 0" flexDir="row" alignItems="center">
        <Select
          variant="outline"
          w={180}
          size="lg"
          value={filterPeriod}
          onChange={handlePeriodChange}
        >
          {filterOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        {loading && (
          <Spinner
            ml="4"
            size="md"
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.700"
          />
        )}
      </Flex>
      {error ? (
        <Text alignSelf="center" marginTop={20}>
          Falha ao obter as estatísticas.
        </Text>
      ) : (
        <Flex
          justifyContent={'space-between'}
          mt={2}
          w="full"
          wrap={isWideVersion ? 'nowrap' : 'wrap'}
          gridColumnGap="8"
        >
          <Flex
            w="full"
            maxW={isWideVersion ? 330 : 'full'}
            wrap="wrap"
            justify="flex-start"
            gridColumnGap="8"
          >
            {loading
              ? [...Array(8)].map((_value, index) => (
                  <SmallCardSkeleton key={index} />
                ))
              : cardValues?.map(value => (
                  <SmallCard key={value.id} value={value} />
                ))}
          </Flex>
          <SimpleGrid
            columns={[1, 1, 2]}
            spacingX="30px"
            spacingY="20px"
            h="min-content"
            w="full"
          >
            {loading ? (
              [...Array(4)].map((_value, index) => (
                <ChartViewSkeleton key={index} />
              ))
            ) : (
              <>
                <ChartView
                  value={specialtyValue}
                  title="TOP 10 Profissionais Por Especialidade"
                />
                <ChartView
                  value={serviceValue}
                  title="TOP 10 Profissionais Por Serviço"
                />
                <ChartView
                  value={cityValue}
                  title="TOP 10 Profissionais Por Cidade"
                />
                <ChartView value={revenueValue} title="Faturamento" isLine />
              </>
            )}
          </SimpleGrid>
        </Flex>
      )}
    </Flex>
  )
}
