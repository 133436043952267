import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { AxiosRequestConfig } from 'axios'

import { api } from '../services/api'

export type Speciality = {
  id: string
  name: string
}

type GetSpecialitiesProps = {
  page?: number
  per_page?: number
}

export type GetSpecialitiesResponse = {
  totalCount: number
  specialities: Speciality[]
}

export async function getSpecialities(
  { page, per_page }: GetSpecialitiesProps = {},
  config?: AxiosRequestConfig
): Promise<GetSpecialitiesResponse> {
  const { data, headers } = await api.get('/admin/fields', {
    params: {
      page: page,
      limit: per_page
    },
    ...config
  })

  const totalCount = Number(headers['x-total-count'] ?? 0)

  return { specialities: data, totalCount }
}

export function useSpecialities(
  { page, per_page }: GetSpecialitiesProps = {},
  options?: UseQueryOptions<GetSpecialitiesResponse>
): UseQueryResult<GetSpecialitiesResponse> {
  return useQuery<GetSpecialitiesResponse>(
    ['specialities', page, per_page],
    () => getSpecialities({ page, per_page }),
    {
      staleTime: 1000 * 10, // Ten seconds
      ...options
    }
  )
}
