import { useState } from 'react'
import { useRef } from 'react'

import {
  Button,
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast
} from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import * as yup from 'yup'

import { Form } from '../../components/Form/Form'
import { Input } from '../../components/Form/Input'
import { api } from '../../services/api'
import { formatYupError } from '../../utils/formatYupError'

type SpecialityModalProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
}

type FormData = {
  name: string
}

export const specialitiesFormSchema = yup.object({
  name: yup
    .string()
    .min(3, 'O nome deve ter no mínimo 3 letras.')
    .required('Nome é obrigatório.')
})

export function SpecialityModal({
  isOpen,
  onClose,
  onSubmit
}: SpecialityModalProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  })

  const handleSubmit: SubmitHandler<FormData> = async data => {
    setIsLoading(true)
    try {
      await specialitiesFormSchema.validate(data, { abortEarly: false })

      await api.post('/admin/fields', data)
      toast({
        status: 'success',
        description: 'Área de atuação criada com sucesso!'
      })

      onSubmit()
      onClose()
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else {
        toast({
          status: 'error',
          description: 'Ocorreu um erro ao tentar criar a área de atuação'
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <ModalHeader>
            <Heading
              textAlign="center"
              fontWeight="medium"
              fontSize="2xl"
              mb="4"
            >
              Nova área de atuação
            </Heading>
            <Divider />
          </ModalHeader>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            h="min"
            w="full"
            p="8"
            borderRadius="8"
          >
            <Input name="name" placeholder="Nome da área de atuação" />
            <Button
              type="submit"
              colorScheme="yellow"
              w="full"
              mt="4"
              isLoading={isLoading}
            >
              Cadastrar
            </Button>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
