import { useEffect, useState } from 'react'
import { useMemo } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'

import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  useToast,
  useDisclosure,
  useBreakpointValue,
  Skeleton
} from '@chakra-ui/react'

import { ConfirmationModal } from '../components/ConfirmationModal'
import { ContentHeader } from '../components/ContentHeader'
import { EditableField } from '../components/EditableInput'
import {
  SpecialityModal,
  specialitiesFormSchema
} from '../components/Modals/SpecialityModal'
import { Pagination } from '../components/Pagination'
import { Speciality, useSpecialities } from '../hooks/useSpecialities'
import { api } from '../services/api'
import { createArray } from '../utils/createArray'

export function Specialities(): JSX.Element {
  const [page, setPage] = useState(1)
  const [registerPerPage, setRegisterPerPage] = useState(10)
  const [removingData, setRemovingData] = useState<Speciality | null>(null)

  const [totalCountOfRegister, setTotalCountOfRegister] = useState(0)
  const { data, isFetching, isLoading, error, refetch } = useSpecialities({
    page,
    per_page: registerPerPage
  })

  const { isOpen, onOpen, onClose } = useDisclosure()
  const isWideVersion = useBreakpointValue({
    base: false,
    md: true
  })

  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  })

  const arrayToRenderSkeleton = useMemo(
    () => createArray(registerPerPage),
    [registerPerPage]
  )

  useEffect(() => {
    if (data) {
      setTotalCountOfRegister(data.totalCount)
    }
  }, [data, isLoading])

  async function handleDeleteSpeciality(): Promise<void> {
    try {
      if (removingData) {
        await api.delete(`/admin/fields/${removingData.id}`)
        toast({
          status: 'success',
          title: 'Sucesso!',
          description: 'A área de atuação foi removida!'
        })
        refetch()
        setRemovingData(null)
      }
    } catch (error) {
      toast({
        status: 'error',
        description: 'Um erro ocorreu ao tentar excluir a área de atuação.'
      })
    }
  }
  async function handleUpdateSpeciality(
    id: string,
    data: Record<string, unknown>
  ): Promise<void> {
    try {
      await specialitiesFormSchema.validate(data, { abortEarly: false })
      await api.put(`/admin/fields/${id}`, data)
      toast({
        status: 'success',
        description: 'Área de atuação atualizada com sucesso!'
      })
      refetch()
    } catch (error) {
      refetch()
      toast({
        status: 'error',
        description: 'Um erro ocorreu ao tentar atualizar a área de atuação.'
      })
    }
  }

  return (
    <>
      <Box
        w="full"
        h="min-content"
        mt="6"
        p={['4', '8']}
        boxShadow="md"
        borderRadius="8"
      >
        <ContentHeader
          title="Áreas de atuação"
          isLoading={!isLoading && isFetching}
        >
          <Button colorScheme="yellow" onClick={onOpen}>
            <Icon as={MdAdd} /> Criar nova área
          </Button>
        </ContentHeader>

        {error ? (
          <Flex>
            <Text>Falha ao obter os dados das áreas de atuação.</Text>
          </Flex>
        ) : (
          <>
            <Box overflowX="auto">
              <Table variant="striped" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th>Nome</Th>
                    <Th w="80px" px="0">
                      Excluir
                    </Th>
                  </Tr>
                </Thead>
                <Tbody w="full" gridGap="3">
                  {isLoading
                    ? arrayToRenderSkeleton.map(index => (
                        <Tr key={index}>
                          <Td h="49px">
                            <Skeleton
                              height="28px"
                              maxW="250px"
                              startColor="gray.100"
                              endColor="gray.200"
                            />
                          </Td>
                          <Td h="49px" px="2">
                            <Skeleton
                              height="32px"
                              width="32px"
                              startColor="gray.100"
                              endColor="gray.200"
                            />
                          </Td>
                        </Tr>
                      ))
                    : data?.specialities.map(speciality => (
                        <Tr key={speciality.id}>
                          <Td whiteSpace="nowrap">
                            <EditableField
                              defaultValue={speciality.name}
                              onSubmit={value =>
                                value !== speciality.name &&
                                handleUpdateSpeciality(speciality.id, {
                                  name: value
                                })
                              }
                            />
                          </Td>

                          <Td px="2">
                            <IconButton
                              onClick={() => setRemovingData(speciality)}
                              icon={<Icon as={MdDelete} w="26px" h="26px" />}
                              color="red.400"
                              _hover={{ bg: 'gray.100' }}
                              variant="unstyled"
                              aria-label={`Excluir área de atuação: ${speciality?.name}`}
                            />
                          </Td>
                        </Tr>
                      ))}
                </Tbody>
              </Table>
            </Box>
            <Pagination
              currentPage={page}
              onPageChange={page => setPage(page)}
              registerPerPage={registerPerPage}
              onRegisterPerPageChange={registerPerPage => {
                setRegisterPerPage(registerPerPage)
                setPage(1)
              }}
              totalCountOfRegister={totalCountOfRegister}
              siblingsCount={isWideVersion ? 2 : 1}
            />
          </>
        )}
      </Box>
      <SpecialityModal isOpen={isOpen} onClose={onClose} onSubmit={refetch} />
      <ConfirmationModal
        isOpen={!!removingData}
        title="Excluir área de atuação?"
        onClose={() => setRemovingData(null)}
        onAccept={handleDeleteSpeciality}
      />
    </>
  )
}
