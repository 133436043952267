import { Stack, Text, Select, HStack } from '@chakra-ui/react'

import { PaginationItem } from './PaginationItem'

interface PaginationProps {
  totalCountOfRegister: number
  registerPerPage?: number
  currentPage?: number
  siblingsCount?: number
  initialValueToRegisterPage?: number
  onPageChange: (page: number, registerPerPage: number) => void
  onRegisterPerPageChange: (registerPerPage: number) => void
}

function generatePagesArray(from: number, to: number): number[] {
  return [...new Array(to - from)]
    .map((_, index) => {
      return from + index + 1
    })
    .filter(page => page > 0)
}

export const Pagination = ({
  totalCountOfRegister,
  onPageChange,
  currentPage = 1,
  siblingsCount = 1,
  initialValueToRegisterPage = 10,
  onRegisterPerPageChange,
  registerPerPage = 10
}: PaginationProps): JSX.Element => {
  const lastPage = Math.ceil(totalCountOfRegister / registerPerPage)

  const previousPage =
    currentPage > 1
      ? generatePagesArray(currentPage - 1 - siblingsCount, currentPage - 1)
      : []
  const nextPage =
    currentPage < lastPage
      ? generatePagesArray(
          currentPage,
          Math.min(currentPage + siblingsCount, lastPage)
        )
      : []

  function handlePageChange(page: number): void {
    onPageChange(page, registerPerPage)
  }
  return (
    <Stack
      w="100%"
      mt="8"
      spacing="6"
      direction={['column', 'column', 'row']}
      align="center"
      justify="space-between"
    >
      <Text>
        <strong>0</strong> - <strong>{registerPerPage}</strong> de{' '}
        {totalCountOfRegister}
      </Text>
      <Stack direction={['column', 'column', 'row']} align="center">
        <Select
          w="100px"
          h="32px"
          focusBorderColor="yellow.300"
          variant="filled"
          cursor="pointer"
          value={registerPerPage}
          onChange={event =>
            onRegisterPerPageChange(Number(event.target.value))
          }
        >
          <option value={initialValueToRegisterPage}>
            {initialValueToRegisterPage}
          </option>
          <option value="20">20</option>
          <option value="30">30</option>
        </Select>
        <HStack>
          {currentPage > 1 + siblingsCount && (
            <>
              <PaginationItem number={1} onPageChange={handlePageChange} />
              {currentPage > 2 + siblingsCount && (
                <Text color="gray.300" textAlign="center" userSelect="none">
                  ...
                </Text>
              )}
            </>
          )}
          {previousPage.length > 0 &&
            previousPage.map(page => (
              <PaginationItem
                number={page}
                key={page}
                onPageChange={handlePageChange}
              />
            ))}
          <PaginationItem
            number={currentPage}
            isCurrent
            onPageChange={handlePageChange}
          />
          {nextPage.length > 0 &&
            nextPage.map(page => (
              <PaginationItem
                number={page}
                key={page}
                onPageChange={handlePageChange}
              />
            ))}
          {currentPage + siblingsCount < lastPage && (
            <>
              {currentPage + 1 + siblingsCount < lastPage && (
                <Text color="gray.300" textAlign="center" userSelect="none">
                  ...
                </Text>
              )}
              <PaginationItem
                number={lastPage}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </HStack>
      </Stack>
    </Stack>
  )
}
