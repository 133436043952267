import { ReactNode } from 'react'

import {
  Flex,
  FlexProps,
  Heading,
  HeadingProps,
  Spinner
} from '@chakra-ui/react'

type ContentHeaderProps = FlexProps & {
  title: string
  isLoading?: boolean
  heading?: HeadingProps
  children?: ReactNode
}

export function ContentHeader({
  title,
  isLoading = false,
  children,
  heading,
  ...rest
}: ContentHeaderProps): JSX.Element {
  return (
    <Flex
      as="header"
      w="full"
      mb="4"
      gridGap="3"
      align="center"
      justify="space-between"
      direction={['column', 'column', 'row']}
      {...rest}
    >
      <Heading as="h2" {...heading} fontSize="2xl">
        {title}
        {isLoading && (
          <Spinner
            ml="4"
            w="18px"
            h="18px"
            speed="0.65s"
            thickness="2px"
            emptyColor="gray.200"
          />
        )}
      </Heading>
      {children}
    </Flex>
  )
}
