import { Flex, Heading, Image } from '@chakra-ui/react'

import notFound from '../assets/img/empty.svg'

export function NotFound(): JSX.Element {
  return (
    <Flex
      w={'full'}
      direction={'column'}
      align={'center'}
      justify={'center'}
      py={20}
    >
      <Heading fontSize="1xl" textAlign={'center'} mb={4}>
        {'Ops!'}
        <br />
        {'Não encontramos resultados para a sua busca'}
      </Heading>
      <Image boxSize={'300px'} src={notFound} alt={'Não foi encontrado'} />
    </Flex>
  )
}
