import { Button } from '@chakra-ui/react'

interface PaginationItemProps {
  number: number
  isCurrent?: boolean
  onPageChange: (page: number) => void
}

export function PaginationItem({
  isCurrent = false,
  number,
  onPageChange
}: PaginationItemProps): JSX.Element {
  if (isCurrent) {
    return (
      <Button
        size="xs"
        fontSize="md"
        position="relative"
        variant="unstyled"
        ml={['2px', '2']}
        disabled
        _disabled={{
          cursor: 'default',
          _after: {
            content: '""',
            position: 'absolute',
            bottom: 1,
            left: '50%',
            width: '8px',
            height: '1px',
            bg: 'black',
            transform: 'translateX(-50%)'
            // translateX: '-50%'
          }
        }}
      >
        {number}
      </Button>
    )
  }
  return (
    <Button
      size="xs"
      fontSize="md"
      variant="unstyled"
      ml={['1', '2']}
      _hover={{
        bg: 'gray.100'
      }}
      aria-label={`Navegar para a página ${number}`}
      onClick={() => onPageChange(number)}
    >
      {number}
    </Button>
  )
}
