import { useState, useEffect } from 'react'

import {
  Center,
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react'

import { api } from '../../services/api'
import { RatingContent } from '../RatingContent'
import { RatingSkeleton } from '../RatingSkeleton'

export type RatingDetail = {
  id: string
  comment: string
  response: string | null
  createdAt: string
  rating: number
  fullName: string
  photo: string
}

type AssessmentsModalProps = {
  isOpen: boolean
  onClose: () => void
  id: string | undefined
}

export function AssessmentsModal({
  isOpen,
  onClose,
  id
}: AssessmentsModalProps): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [ratingData, setRatingData] = useState<RatingDetail>()
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    async function getDetail(): Promise<void> {
      if (id) {
        try {
          setIsLoading(true)
          const { data } = await api.get<RatingDetail>(
            `admin/ratings/detail/${id}`
          )
          setRatingData(data)
        } catch {
          setError(true)
        } finally {
          setIsLoading(false)
        }
      }
    }
    getDetail()
  }, [id])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
      <ModalOverlay />
      <ModalContent my="auto" bgColor={'white'}>
        <ModalBody p={0}>
          <ModalHeader p={0}>
            <Heading fontWeight="medium" fontSize="2xl" p={4}>
              Detalhes
            </Heading>
            <Divider />
          </ModalHeader>
          {error ? (
            <Center py={20}>
              <Text alignSelf="center">Falha ao obter dados da avaliação.</Text>
            </Center>
          ) : !isLoading && ratingData ? (
            <RatingContent rating={ratingData} />
          ) : (
            <RatingSkeleton />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
