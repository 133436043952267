import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { AxiosRequestConfig } from 'axios'

import { api } from '../services/api'

export type Service = {
  id: string
  name: string
  field: string
}

type GetServicesProps = {
  page?: number
  per_page?: number
}

export type GetServicesResponse = {
  totalCount: number
  services: Service[]
}

export async function getServices(
  { page, per_page }: GetServicesProps = {},
  config?: AxiosRequestConfig
): Promise<GetServicesResponse> {
  const { data, headers } = await api.get('/admin/services', {
    params: {
      page: page,
      limit: per_page
    },
    ...config
  })

  const totalCount = Number(headers['x-total-count'] ?? 0)

  return { services: data, totalCount }
}

export function useServices(
  { page, per_page }: GetServicesProps = {},
  options?: UseQueryOptions<GetServicesResponse>
): UseQueryResult<GetServicesResponse> {
  return useQuery<GetServicesResponse>(
    ['services', page, per_page],
    () => getServices({ page, per_page }),
    {
      staleTime: 1000 * 10, // Ten seconds
      ...options
    }
  )
}
