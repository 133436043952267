import { useEffect, useState } from 'react'

import { Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react'

import { PlanContainer, PlansProps } from '../components/PlanContainer'
import { PlanSkeleton } from '../components/PlanSkeleton'
import { api } from '../services/api'

export function Plans(): JSX.Element {
  const [plans, setPlans] = useState<PlansProps[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    async function getPlans(): Promise<void> {
      try {
        setLoading(true)
        const { data } = await api.get('admin/plans')
        setPlans(data)
      } catch {
        setError(false) // trocar
      } finally {
        setLoading(false)
      }
    }
    getPlans()
  }, [])

  async function refresh(): Promise<void> {
    try {
      const { data } = await api.get<PlansProps[]>('admin/plans')
      setPlans(data)
    } catch {
      setError(false) // trocar
    }
  }

  return (
    <Flex w="full" p={8} flexDir={'column'}>
      <Heading fontSize="xl" fontWeight={400}>
        Configurações de Planos
      </Heading>
      {error ? (
        <Text alignSelf="center" marginTop={20}>
          Falha ao obter os dados dos planos.
        </Text>
      ) : (
        <SimpleGrid
          columns={[1, 2, 3]}
          w="full"
          minChildWidth="285px"
          spacingX="40px"
          spacingY="36px"
          pt="50px"
        >
          {loading ? (
            <>
              <PlanSkeleton />
              <PlanSkeleton />
              <PlanSkeleton />
            </>
          ) : (
            plans?.map(plan => (
              <PlanContainer key={plan.id} plan={plan} refresh={refresh} />
            ))
          )}
        </SimpleGrid>
      )}
    </Flex>
  )
}
