import { memo, useRef, useState } from 'react'

import { useToast } from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import axios from 'axios'
import * as yup from 'yup'

import { Form } from '../../components/Form/Form'
import { Input } from '../../components/Form/Input'
import { FormContainer } from '../../components/FormContainer'
import { useAuth } from '../../hooks/useAuth'
import { api } from '../../services/api'
import { formatYupError } from '../../utils/formatYupError'
import { updateFormSchema } from './validationSchema'

type FormData = {
  fullName: string
  email: string
}

export function SelfUpdateRaw(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false)
  const formRef = useRef<FormHandles>(null)
  const { user, setNewInformation } = useAuth()
  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  })

  const handleSubmit: SubmitHandler<FormData> = async data => {
    setLoading(true)
    try {
      await updateFormSchema.validate(data, { abortEarly: false })
      await api.put(`admin/settings/profile`, data)
      await setNewInformation()
      toast({
        status: 'success',
        title: 'Sucesso!',
        description: 'Alteração feita com sucesso!.'
      })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else if (axios.isAxiosError(error)) {
        toast({
          status: 'error',
          description: 'Ocorreu um erro ao tentar salvar seus dados.'
        })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      initialData={{ fullName: user?.fullName, email: user?.email }}
      ref={formRef}
      onSubmit={handleSubmit}
    >
      <FormContainer
        headerText={'Dados Cadastrais'}
        buttonText={'Salvar Alterações'}
        loading={loading}
      >
        <Input
          name="fullName"
          label="Nome Completo (obrigatório)"
          placeholder="Informe seu nome completo"
          mb="4"
        />
        <Input
          name="email"
          label="Email (obrigatório)"
          placeholder="Informe seu email"
          mb="4"
        />
      </FormContainer>
    </Form>
  )
}

export const SelfUpdate = memo(SelfUpdateRaw)
