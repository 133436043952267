import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { MdDelete } from 'react-icons/md'

import {
  Box,
  Flex,
  Icon,
  IconButton,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { ConfirmationModal } from '../../components/ConfirmationModal'
import { FormContainer } from '../../components/FormContainer'
import { Pagination } from '../../components/Pagination'
import { Admin, GetAdminsResponse } from '../../hooks/useAdmins'
import { useAuth } from '../../hooks/useAuth'
import { api } from '../../services/api'
import { createArray } from '../../utils/createArray'

type ListAdminProps = {
  props: {
    page: number
    setPage: Dispatch<SetStateAction<number>>
    registerPerPage: number
    setRegisterPerPage: Dispatch<SetStateAction<number>>
    data?: GetAdminsResponse
    isLoading: boolean
    isFetching: boolean
    error: unknown
    refetch: () => void
  }
}

export function ListAdmin({
  props: {
    data,
    error,
    isFetching,
    isLoading,
    page,
    refetch,
    registerPerPage,
    setPage,
    setRegisterPerPage
  }
}: ListAdminProps): JSX.Element {
  const [totalCountOfRegister, setTotalCountOfRegister] = useState(0)
  const [removingData, setRemovingData] = useState<Admin | null>(null)
  const { user } = useAuth()
  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  })

  const arrayToRenderSkeleton = useMemo(
    () => createArray(registerPerPage),
    [registerPerPage]
  )

  const isWideVersion = useBreakpointValue({
    base: false,
    md: true
  })

  useEffect(() => {
    if (data) {
      setTotalCountOfRegister(data.totalCount)
    }
  }, [data, setTotalCountOfRegister])

  async function handleDeleteAdmin(): Promise<void> {
    try {
      if (removingData) {
        await api.delete(`/admin/settings/admins/${removingData.id}`)
        toast({
          status: 'success',
          title: 'Sucesso!',
          description: 'O administrador foi removido.'
        })
        refetch()
        setRemovingData(null)
      }
    } catch (error) {
      toast({
        status: 'error',
        description: 'Um erro ocorreu ao tentar remover o administrador.'
      })
    }
  }
  return (
    <FormContainer
      headerText={'Administradores'}
      offButton
      loadingData={!isLoading && isFetching}
    >
      <Box w="full">
        {error ? (
          <Flex>
            <Text>Falha ao obter os dados dos administradores.</Text>
          </Flex>
        ) : (
          <>
            <Box overflowX="auto" overflow="auto" maxH="280px">
              <Table variant="striped" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th>Nome</Th>
                    <Th>Email</Th>
                    <Th w="80px" px="0">
                      Excluir
                    </Th>
                  </Tr>
                </Thead>
                <Tbody w="full" gridGap="3">
                  {isLoading
                    ? arrayToRenderSkeleton.map(index => (
                        <Tr key={index}>
                          <Td h="49px" py="1">
                            <Skeleton
                              height="28px"
                              maxW="250px"
                              startColor="gray.100"
                              endColor="gray.200"
                            />
                          </Td>
                          <Td h="49px" py="1">
                            <Skeleton
                              height="32px"
                              width="32px"
                              startColor="gray.100"
                              endColor="gray.200"
                            />
                          </Td>
                          <Td h="49px" py="1">
                            <Skeleton
                              height="32px"
                              width="32px"
                              startColor="gray.100"
                              endColor="gray.200"
                            />
                          </Td>
                        </Tr>
                      ))
                    : data?.admins.map(admin => (
                        <Tr key={admin.id}>
                          <Td>{admin.fullName}</Td>
                          <Td>{admin.email}</Td>
                          <Td px="2">
                            <IconButton
                              disabled={!user?.master}
                              onClick={() => setRemovingData(admin)}
                              icon={<Icon as={MdDelete} w="26px" h="26px" />}
                              color="red.400"
                              variant="unstyled"
                              aria-label={`Excluir admin: ${admin?.fullName}`}
                            />
                          </Td>
                        </Tr>
                      ))}
                </Tbody>
              </Table>
            </Box>
            <Pagination
              currentPage={page}
              onPageChange={page => setPage(page)}
              registerPerPage={registerPerPage}
              onRegisterPerPageChange={registerPerPage => {
                setRegisterPerPage(registerPerPage)
                setPage(1)
              }}
              totalCountOfRegister={totalCountOfRegister}
              siblingsCount={isWideVersion ? 2 : 1}
            />
          </>
        )}
      </Box>
      <ConfirmationModal
        isOpen={!!removingData}
        title="Excluir admin?"
        onClose={() => setRemovingData(null)}
        onAccept={handleDeleteAdmin}
      />
    </FormContainer>
  )
}
