import { useAuth } from '../hooks/useAuth'
import { LoadingPage } from '../pages/loading'
import { RoutesWithAuth } from './routesWithAuth'
import { RoutesWithoutAuth } from './routesWithoutAuth'

export function Routes(): JSX.Element {
  const { isAuthenticated, loading } = useAuth()

  if (loading) {
    return <LoadingPage />
  }

  return isAuthenticated ? <RoutesWithAuth /> : <RoutesWithoutAuth />
}
