import { Box, Center, Skeleton } from '@chakra-ui/react'

export function SmallCardSkeleton(): JSX.Element {
  return (
    <Box
      d={'flex'}
      flexDirection={'column'}
      borderRadius="6"
      boxShadow="md"
      p="11"
      bg="white"
      w={['full', 145]}
      h={131}
      mb={8}
    >
      <Skeleton height="10px" w={20} mb={4} mt={2} />
      <Center mt={4}>
        <Skeleton height="20px" w={50} />
      </Center>
    </Box>
  )
}
