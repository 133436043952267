import { ReactNode } from 'react'

import { Flex, useBreakpointValue } from '@chakra-ui/react'

import { SideBarDrawerProvider } from '../contexts/SideBarDrawerContext'
import { Header } from './Header'
import { SideBar } from './SideBar'

type PageTemplateProps = {
  children?: ReactNode
}

export function PageTemplate({ children }: PageTemplateProps): JSX.Element {
  const isDrawerSidebar = useBreakpointValue({
    base: true,
    lg: false
  })

  return (
    <SideBarDrawerProvider>
      <Flex
        w="full"
        h="100vh"
        mx="auto"
        direction="column"
        pl={isDrawerSidebar ? '0' : '60'}
      >
        {isDrawerSidebar && <Header />}
        <SideBar />
        <Flex w="full" maxW={1200} p="4" mx="auto">
          {children}
        </Flex>
      </Flex>
    </SideBarDrawerProvider>
  )
}
