import { Route, Switch } from 'react-router-dom'

import { SignInLayout } from '../components/SignInLayout'
import { Forgot } from '../pages/forgot'
import { Reset } from '../pages/reset'
import { SignIn } from '../pages/signin'

export function RoutesWithoutAuth(): JSX.Element {
  return (
    <Switch>
      <SignInLayout>
        <Route path="/" component={SignIn} exact />
        <Route path="/forgot" component={Forgot} exact />
        <Route path="/reset" component={Reset} />
      </SignInLayout>
    </Switch>
  )
}
