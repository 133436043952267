import {
  Box,
  Flex,
  Skeleton,
  SkeletonCircle,
  SkeletonText
} from '@chakra-ui/react'

export function RatingSkeleton(): JSX.Element {
  return (
    <Box d={'flex'} flexDirection={'row'} padding="6" bg="white" mb={5}>
      <SkeletonCircle size="10" mr={2} />
      <Flex direction={'column'} w={'full'}>
        <Skeleton height="10px" w={20} mb={4} mt={2} />
        <Skeleton height="10px" w={150} />
        <SkeletonText mt="4" noOfLines={3} spacing="4" />
      </Flex>
    </Box>
  )
}
