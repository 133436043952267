import { useState, useRef, useMemo } from 'react'

import {
  Button,
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast
} from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import * as yup from 'yup'

import { Form } from '../../components/Form/Form'
import { Input } from '../../components/Form/Input'
import { useSpecialities } from '../../hooks/useSpecialities'
import { api } from '../../services/api'
import { formatYupError } from '../../utils/formatYupError'
import { Option, Select } from '../Form/Select'

type ServiceModalProps = {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
}

type FormData = {
  name: string
  fieldId: string
}

export const servicesFormSchema = yup.object({
  name: yup
    .string()
    .min(3, 'O nome deve ter no mínimo 3 letras.')
    .required('Nome é obrigatório.'),
  fieldId: yup.string().required('A área de atuação é obrigatória')
})

export function ServiceModal({
  isOpen,
  onClose,
  onSubmit
}: ServiceModalProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)
  const formRef = useRef<FormHandles>(null)
  const { data } = useSpecialities()
  const options: Option[] = useMemo(() => {
    if (!data) return []
    return data?.specialities.map(speciality => ({
      value: speciality.id,
      label: speciality.name
    }))
  }, [data])
  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  })

  const handleSubmit: SubmitHandler<FormData> = async data => {
    setIsLoading(true)
    try {
      await servicesFormSchema.validate(data, { abortEarly: false })

      await api.post('/admin/services', data)
      toast({
        status: 'success',
        description: 'Serviço criado com sucesso!'
      })
      onSubmit()
      onClose()
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else {
        toast({
          title: 'Ocorreu um erro!',
          description: 'Ocorreu um erro ao tentar criar o serviço.'
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <ModalHeader>
            <Heading fontWeight="medium" fontSize="2xl" mb="4">
              Novo Serviço
            </Heading>
            <Divider />
          </ModalHeader>
          <Form ref={formRef} onSubmit={handleSubmit} px="6" pb="6">
            <Input
              name="name"
              label="Nome (obrigatório)"
              placeholder="Informe o nome"
              mb="4"
            />
            <Select
              name="fieldId"
              label="Área de atuação (obrigatório)"
              placeholder="Selecione a área de atuação"
              options={options}
            />
            <Button
              type="submit"
              colorScheme="yellow"
              maxW="112px"
              w="full"
              mt="4"
              ml="auto"
              d="block"
              isLoading={isLoading}
            >
              Cadastrar
            </Button>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
