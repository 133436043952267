import { Box, Flex, Text } from '@chakra-ui/react'

import { formatPriceWithoutDecimals } from '../utils/formatPrice'

export type CardProps = {
  id: number
  type: string
  name: string
  value: number | null
}

type SmallCardProps = {
  value: CardProps
}

export function SmallCard({ value }: SmallCardProps): JSX.Element {
  const isPrice = value.type === 'totalValue'
  const isRating = value.type === 'rating'

  function formatDecimals(value: number | string): number | undefined | string {
    const number = value as number
    if (number) {
      if (number > 4.99) {
        return Math.trunc(number)
      } else {
        return parseInt(String(number)).toFixed(1)
      }
    }
  }

  function formatValue(
    value: number | undefined | null
  ): number | string | undefined {
    if (value) {
      if (isRating) {
        return value !== null ? formatDecimals(value) : 'N/A'
      }
      if (isPrice) {
        return formatPriceWithoutDecimals(value)
      }
      if (!isRating || !isPrice) {
        return value
      }
    } else {
      return String(value)
    }
  }

  return (
    <Box
      w={['full', 145]}
      h={131}
      borderRadius="6"
      boxShadow="md"
      p="11"
      mb={8}
    >
      <Text fontSize={12}>{value?.name}</Text>
      <Flex
        direction="row"
        justify="center"
        alignItems="center"
        h={'80px'}
        w="full"
      >
        <Text
          fontWeight={700}
          fontSize={
            isPrice
              ? [
                  String(value?.value).length < 7 ? 25 : 18,
                  String(value?.value).length < 7 ? 30 : 20
                ]
              : String(value?.value).length < 7
              ? 35
              : 22
          }
          color="green.500"
        >
          {formatValue(value?.value)}
        </Text>
      </Flex>
    </Box>
  )
}
