import { ReactNode } from 'react'

import { Box, Text, Stack } from '@chakra-ui/react'

type NavSectionProps = {
  title: string
  children?: ReactNode
}

export function NavSection({ title, children }: NavSectionProps): JSX.Element {
  return (
    <Box w="full">
      <Text
        fontWeight="bold"
        color="gray.400"
        fontSize="small"
        ml={['8', '8', '5']}
      >
        {title}
      </Text>
      <Stack spacing="0" mt="4" align="stretch">
        {children}
      </Stack>
    </Box>
  )
}
