import { Avatar, Box, Flex, Text } from '@chakra-ui/react'

import { formatDate } from '../utils/formatDate'
import { RatingDetail } from './Modals/AssessmentsModal'
import { RatingStars } from './RatingStars'

type RatingContentProps = {
  rating: RatingDetail
}

export function RatingContent({ rating }: RatingContentProps): JSX.Element {
  const hasResponse = !!rating.response

  return (
    <Flex w={'full'} h={'min-content'} p={5} direction={'row'} mb={5}>
      <Avatar
        color={'white'}
        fontSize={12}
        bgColor={'green.500'}
        size="sm"
        name={rating.fullName}
        marginRight={3}
      />
      <Flex direction={'column'} w={'full'}>
        <Box>
          <Text fontSize={14}>{rating.fullName}</Text>
          <Flex alignItems={'center'} textAlign={'center'}>
            <RatingStars userRating={rating.rating} />
            <Text fontSize={10}>{formatDate(rating.createdAt)}</Text>
          </Flex>
        </Box>
        <Text fontSize={14} p={2} mb={3}>
          {rating.comment}
        </Text>

        {hasResponse && (
          <Flex flexDir={'row'} ml={3}>
            <Box
              bgColor={'gray.50'}
              borderRadius={'20px 20px 0px 20px'}
              p={4}
              w={'full'}
              mr={2}
            >
              <Text>{rating.response}</Text>
            </Box>
            <Avatar
              alignSelf={'end'}
              color={'white'}
              fontSize={12}
              bgColor={'green.300'}
              size="sm"
              src={rating.photo}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
