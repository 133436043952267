import { Box, Skeleton } from '@chakra-ui/react'

export function ChartViewSkeleton(): JSX.Element {
  return (
    <Box
      d={'flex'}
      flexDirection={'column'}
      bg="white"
      w="full"
      borderRadius="6"
      boxShadow="md"
      py={8}
      px="5"
      alignItems="center"
      justifyContent="center"
    >
      <Skeleton height="15px" w={200} mb={4} mt={2} />
      <Skeleton height="198px" w={200} />
    </Box>
  )
}
