import { ReactNode } from 'react'

import { Button, Divider, Flex, Heading, Spinner } from '@chakra-ui/react'

type FormContainerProps = {
  children: ReactNode
  buttonText?: string
  headerText: string
  offButton?: boolean
  loading?: boolean
  loadingData?: boolean
  buttonIsDisabled?: boolean
  handleClick?: () => void
}

export function FormContainer({
  buttonText,
  headerText,
  offButton,
  loading,
  loadingData,
  buttonIsDisabled,
  handleClick,
  children
}: FormContainerProps): JSX.Element {
  return (
    <Flex
      w="full"
      h="full"
      py="5"
      px="9"
      borderRadius="6"
      boxShadow="md"
      d="flex"
      flexDirection="column"
    >
      <Heading fontSize="xl">
        {headerText}
        {loadingData && (
          <Spinner
            ml="4"
            size="md"
            thickness="2px"
            speed="0.65s"
            emptyColor="gray.700"
          />
        )}
      </Heading>
      <Divider my="4" />
      {children}
      {!offButton && (
        <Button
          type="submit"
          alignSelf={'flex-end'}
          colorScheme="yellow"
          w="120px"
          h="35px"
          mt="4"
          onClick={() => handleClick && handleClick()}
          isDisabled={buttonIsDisabled}
          fontSize={'small'}
          isLoading={loading}
        >
          {buttonText}
        </Button>
      )}
    </Flex>
  )
}
