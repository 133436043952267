import { useEffect, useState } from 'react'
import { IoStar } from 'react-icons/all'

import { Flex, Icon } from '@chakra-ui/react'

type RatingStarsProps = {
  userRating: number
}

export function RatingStars({ userRating }: RatingStarsProps): JSX.Element {
  const [rating, setRating] = useState<number>(0)

  useEffect(() => {
    setRating(userRating)
  }, [userRating])

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'center'}
      mr={2}
      mb={0.5}
      h={'min-content'}
    >
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1

        return (
          <Icon
            key={i}
            as={IoStar}
            color={ratingValue <= rating ? 'yellow.300' : 'gray.200'}
            w={3}
            h={3}
            marginRight={0.5}
          />
        )
      })}
    </Flex>
  )
}
