import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { AxiosRequestConfig } from 'axios'

import { api } from '../services/api'

export type Admin = {
  id: string
  fullName: string
  email: string
}

type GetAdminsProps = {
  page?: number
  per_page?: number
}

export type GetAdminsResponse = {
  totalCount: number
  admins: Admin[]
}

export async function getAdmins(
  { page, per_page }: GetAdminsProps = {},
  config?: AxiosRequestConfig
): Promise<GetAdminsResponse> {
  const { data, headers } = await api.get('/admin/settings/admins', {
    params: {
      page: page,
      limit: per_page
    },
    ...config
  })
  const totalCount = Number(headers['x-total-count'] ?? 0)

  return { admins: data, totalCount }
}

export function useAdmins(
  { page, per_page }: GetAdminsProps,
  options?: UseQueryOptions<GetAdminsResponse>
): UseQueryResult<GetAdminsResponse> {
  return useQuery<GetAdminsResponse>(
    ['admins', page, per_page],
    () => getAdmins({ page, per_page }),
    {
      staleTime: 1000 * 10, // Ten seconds
      ...options
    }
  )
}
