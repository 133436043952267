function formatCpf(value: string): string {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

function formatCnpj(value: string): string {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export default function formatCpfCnpj(value: string): string {
  const cleanValue = String(value).replace(/\D/g, '')

  if (cleanValue.length <= 11) {
    return formatCpf(value)
  }
  return formatCnpj(value)
}

export const formatMoney = (value: number | string): string => {
  const formattedValue = typeof value === 'number' ? value.toFixed(2) : value
  return String(formattedValue)
    .replace(/\./g, '')
    .replace(/,/g, '.')
    .replace(/\D/g, '')
    .replace(/(\d)/, `${String(value).length === 0 ? '' : 'R$'} $1`)
    .replace(/(\d{20})\d$/, '$1')
    .replace(/(\d)(\d{17}$)/, '$1.$2')
    .replace(/(\d)(\d{14}$)/, '$1.$2')
    .replace(/(\d)(\d{11}$)/, '$1.$2')
    .replace(/(\d)(\d{8}$)/, '$1.$2')
    .replace(/(\d)(\d{5}$)/, '$1.$2')
    .replace(/(\d)(\d{2}?$)/, '$1,$2')
}

export const masks = {
  price: formatMoney
}
