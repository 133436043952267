import { MdEdit } from 'react-icons/md'

import {
  Editable,
  EditableInput,
  EditablePreview,
  EditableProps,
  Icon
} from '@chakra-ui/react'

type EditableFieldProps = EditableProps

export function EditableField({ ...rest }: EditableFieldProps): JSX.Element {
  return (
    <Editable d="flex" alignItems="center" gridGap="2" {...rest}>
      <EditableInput maxW="250px" px="3" py="2" />
      <EditablePreview />
      <Icon as={MdEdit} color="blue.300" fontSize="22" />
    </Editable>
  )
}
