import { ReactNode } from 'react'

import { Center, Grid, Img, useBreakpointValue, Box } from '@chakra-ui/react'

import logo from '../assets/img/logo.svg'
import bkg from '../assets/img/signInBkg.webp'

type SignInLayout = {
  children: ReactNode
}

export function SignInLayout({ children }: SignInLayout): JSX.Element {
  const isWideVersion = useBreakpointValue({ base: false, md: true })
  return (
    <Grid
      w="full"
      h="100vh"
      gridTemplateColumns={isWideVersion ? '1fr 1fr' : '1fr'}
      gridTemplateRows={isWideVersion ? '1fr' : '185px 1fr'}
    >
      <Box pos="relative">
        <Img
          src={bkg}
          alt="advogados"
          w="full"
          h={isWideVersion ? '100%' : '185px'}
          objectFit="cover"
          objectPosition={['', 'left']}
        />
        <Img
          src={logo}
          alt="uniadvogados"
          pos="absolute"
          w="100%"
          maxW={['200px', '300px', '400px']}
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        />
      </Box>

      <Center p="4">{children}</Center>
    </Grid>
  )
}
