import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { MdDelete, MdSearch } from 'react-icons/md'

import {
  Box,
  Button,
  Icon,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  Text,
  useToast,
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Select
} from '@chakra-ui/react'

import { ConfirmationModal } from '../components/ConfirmationModal'
import { ContentHeader } from '../components/ContentHeader'
import { NotFound } from '../components/NotFound'
import { Pagination } from '../components/Pagination'
import { PlansProps } from '../components/PlanContainer'
import { TableSkeleton } from '../components/TableSkeleton'
import { Lawyer, useLawyers } from '../hooks/useLawyers'
import { api } from '../services/api'
import { theme } from '../styles/theme'
import { createArray } from '../utils/createArray'

let debounce: NodeJS.Timeout

type SelectedPlanProps = {
  uuid: string
  planId: string
}

export function Lawyers(): JSX.Element {
  const [page, setPage] = useState(1)
  const [plansOptions, setPlansOptions] = useState<PlansProps[]>()
  const [selectedPlan, setSelectedPlan] = useState<SelectedPlanProps | null>(
    null
  )
  const [registerPerPage, setRegisterPerPage] = useState(10)
  const [totalCountOfRegister, setTotalCountOfRegister] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const [removingData, setRemovingData] = useState<Lawyer | null>(null)
  const { data, isLoading, isFetching, error, refetch } = useLawyers({
    page,
    per_page: registerPerPage,
    filter: inputValue
  })

  const isWideVersion = useBreakpointValue({
    base: false,
    md: true
  })

  const arrayToRenderSkeleton = useMemo(
    () => createArray(registerPerPage),
    [registerPerPage]
  )

  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  })

  useEffect(() => {
    if (data) {
      setTotalCountOfRegister(data.totalCount)
    }
  }, [data, isLoading])

  useEffect(() => {
    async function getPlanOptions(): Promise<void | boolean> {
      try {
        const { data } = await api.get('admin/plans')
        return setPlansOptions(data)
      } catch {
        return false
      }
    }
    getPlanOptions()
  }, [])

  async function handleDeleteLawyer(): Promise<void> {
    try {
      if (removingData) {
        await api.delete(`admin/lawyers/${removingData.id}`)
        toast({
          status: 'success',
          title: 'Sucesso!',
          description: 'O advogado foi removido.'
        })
        refetch()
        setRemovingData(null)
      }
    } catch (error) {
      toast({
        status: 'error',
        title: 'Erro!',
        description: 'Um erro ocorreu ao tentar excluir o advogado.'
      })
    }
  }

  async function handleChangeInput(
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    clearTimeout(debounce)

    debounce = setTimeout(() => {
      if (event.target.value.length <= 3) {
        setInputValue('')
      } else {
        setInputValue(event.target.value)
      }
    }, 1000)
  }

  async function handleExportCsv(): Promise<void> {
    try {
      const { data } = await api.get('admin/lawyers/csv')
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' })
      const csvURL = window.URL.createObjectURL(blob)
      window.open(csvURL)
    } catch (error) {
      toast({
        status: 'error',
        title: 'Erro!',
        description: 'Erro ao exportar!'
      })
    }
  }

  function handleSelectChange(planId: string, uuid: string): void {
    setSelectedPlan({
      uuid,
      planId
    })
  }

  async function handlePlanChange(): Promise<void> {
    if (selectedPlan) {
      const { uuid, planId } = selectedPlan
      try {
        await api.put(`admin/lawyers/${uuid}/${planId}`)
        toast({
          title: 'Sucesso!',
          status: 'success',
          description: 'Plano alterado com sucesso!'
        })
        setSelectedPlan(null)
        refetch()
      } catch {
        toast({
          title: 'Erro!',
          status: 'error',
          description: 'Erro ao mudar o plano!'
        })
      }
    }
  }

  return (
    <>
      <Box w="full" h="min-content" p="8" borderRadius="8" boxShadow="md">
        <ContentHeader
          title="Advogados"
          isLoading={!isLoading && isFetching}
          direction={isWideVersion ? 'row' : 'column'}
          heading={{ d: 'flex', flexWrap: 'nowrap', alignItems: 'baseline' }}
        >
          <InputGroup maxW="400px">
            <InputLeftElement>
              <Icon as={MdSearch} fontSize="22" />
            </InputLeftElement>
            <Input
              placeholder="Buscar por nome, CPF, OAB, gênero, data de nasc..."
              onChange={handleChangeInput}
              _focus={{
                boxShadow: `0 0 0 1px ${theme.colors.yellow['300']}`,
                borderColor: 'yellow.300'
              }}
            />
            {isLoading && (
              <InputRightElement>
                <Spinner size="sm" />
              </InputRightElement>
            )}
          </InputGroup>
          <Button colorScheme="yellow" minW="120px" onClick={handleExportCsv}>
            Exportar CSV
          </Button>
        </ContentHeader>

        {error ? (
          <Text>Falha ao obter os dados dos advogados.</Text>
        ) : (
          <>
            {isLoading ? (
              <TableSkeleton skeleton={arrayToRenderSkeleton}>
                <Th>Nome</Th>
                <Th>Email</Th>
                <Th>Plano</Th>
                <Th>OAB</Th>
                <Th>CPF/CNPJ</Th>
                <Th textAlign="center">Nota</Th>
                <Th w="60px" textAlign="center">
                  Visitas na página
                </Th>
                <Th w="50px" textAlign="center">
                  Cliques Whatsapp
                </Th>
                <Th w="80px" px="0">
                  Excluir
                </Th>
              </TableSkeleton>
            ) : data?.lawyers && data.lawyers.length > 0 ? (
              <>
                <Box overflowX="auto">
                  <Table variant="striped" colorScheme="gray">
                    <Thead>
                      <Tr>
                        <Th>Nome</Th>
                        <Th>Email</Th>
                        <Th>Plano</Th>
                        <Th>OAB</Th>
                        <Th>CPF/CNPJ</Th>
                        <Th textAlign="center">Nota</Th>
                        <Th w="60px" textAlign="center">
                          Visitas na página
                        </Th>
                        <Th w="50px" textAlign="center">
                          Cliques Whatsapp
                        </Th>
                        <Th w="80px" px="0">
                          Excluir
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody w="full" gridGap="3">
                      {data?.lawyers.map(lawyer => (
                        <Tr key={lawyer.id}>
                          <Td whiteSpace="nowrap">{lawyer.fullName}</Td>
                          <Td whiteSpace="nowrap">{lawyer.email}</Td>
                          <Td whiteSpace="nowrap">
                            <Select
                              w={109}
                              borderRadius={6}
                              value={lawyer.plan || 'none'}
                              size={'sm'}
                              onChange={e =>
                                handleSelectChange(e.target.value, lawyer.id)
                              }
                            >
                              {plansOptions?.map(option => (
                                <option
                                  key={option.id}
                                  value={option.slug || ''}
                                >
                                  {option.name}
                                </option>
                              ))}
                            </Select>
                          </Td>
                          <Td whiteSpace="nowrap">{lawyer.oab}</Td>
                          <Td whiteSpace="nowrap">{lawyer.cpfCnpj}</Td>
                          <Td textAlign="center">{lawyer.rating}</Td>
                          <Td textAlign="center">{lawyer.visits}</Td>
                          <Td textAlign="center">{lawyer.clicks}</Td>
                          <Td px="2">
                            <IconButton
                              onClick={() => setRemovingData(lawyer)}
                              icon={<Icon as={MdDelete} w="26px" h="26px" />}
                              color="red.400"
                              _hover={{ bg: 'gray.100' }}
                              variant="unstyled"
                              aria-label={`Excluir serviço: ${lawyer?.fullName}`}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Box>
                <Pagination
                  currentPage={page}
                  onPageChange={page => setPage(page)}
                  registerPerPage={registerPerPage}
                  onRegisterPerPageChange={registerPerPage => {
                    setRegisterPerPage(registerPerPage)
                    setPage(1)
                  }}
                  totalCountOfRegister={totalCountOfRegister}
                  siblingsCount={isWideVersion ? 2 : 1}
                />
              </>
            ) : (
              <NotFound />
            )}
          </>
        )}
      </Box>
      <ConfirmationModal
        isConfirmation={!!selectedPlan}
        isOpen={!!selectedPlan || !!removingData}
        title={selectedPlan ? 'Deseja alterar o plano?' : 'Excluir serviço?'}
        onClose={() =>
          selectedPlan ? setSelectedPlan(null) : setRemovingData(null)
        }
        onAccept={selectedPlan ? handlePlanChange : handleDeleteLawyer}
      />
    </>
  )
}
