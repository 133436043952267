import { useState } from 'react'
import { useMemo, useEffect } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'

import {
  Box,
  Button,
  Icon,
  IconButton,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import * as yup from 'yup'

import { ConfirmationModal } from '../components/ConfirmationModal'
import { ContentHeader } from '../components/ContentHeader'
import { EditableField } from '../components/EditableInput'
import { ServiceModal } from '../components/Modals/ServiceModal'
import { Pagination } from '../components/Pagination'
import { Service, useServices } from '../hooks/useServices'
import { api } from '../services/api'
import { createArray } from '../utils/createArray'
import { formatYupError } from '../utils/formatYupError'

const servicesFormSchema = yup.object({
  name: yup
    .string()
    .min(3, 'O nome deve ter no mínimo 3 letras')
    .required('Nome é obrigatório')
})

export function Services(): JSX.Element {
  const [page, setPage] = useState(1)
  const [registerPerPage, setRegisterPerPage] = useState(10)
  const [totalCountOfRegister, setTotalCountOfRegister] = useState(0)
  const [removingData, setRemovingData] = useState<Service | null>(null)
  const { data, isLoading, isFetching, error, refetch, remove } = useServices({
    page,
    per_page: registerPerPage
  })

  const { onOpen, onClose, isOpen } = useDisclosure()
  const isWideVersion = useBreakpointValue({
    base: false,
    md: true
  })

  const arrayToRenderSkeleton = useMemo(
    () => createArray(registerPerPage),
    [registerPerPage]
  )

  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  })

  useEffect(() => {
    if (data) {
      setTotalCountOfRegister(data.totalCount)
    }
  }, [data, isLoading])

  async function handleDeleteService(): Promise<void> {
    try {
      if (removingData) {
        await api.delete(`/admin/services/${removingData.id}`)
        toast({
          status: 'success',
          title: 'Sucesso!',
          description: 'O serviço foi removido.'
        })
        refetch()
        setRemovingData(null)
      }
    } catch (error) {
      toast({
        status: 'error',
        description: 'Um erro ocorreu ao tentar excluir o serviço.'
      })
    }
  }

  async function handleUpdateService(
    id: string,
    data: Record<string, unknown>
  ): Promise<void> {
    try {
      await servicesFormSchema.validate(data, { abortEarly: false })
      await api.put(`/admin/services/${id}`, data)
      toast({
        status: 'success',
        description: 'Serviço atualizado com sucesso!'
      })
      refetch()
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const formattedError = formatYupError(error)
        remove()
        refetch()
        toast({
          status: 'warning',
          description: formattedError.name
        })
      } else {
        refetch()
        toast({
          status: 'error',
          description: 'Um erro ocorreu ao tentar atualizar o serviço.'
        })
      }
    }
  }

  return (
    <>
      <Box w="full" h="min-content" p="8" borderRadius="8" boxShadow="md">
        <ContentHeader isLoading={!isLoading && isFetching} title="Serviços">
          <Button colorScheme="yellow" onClick={onOpen}>
            <Icon as={MdAdd} /> Criar novo serviço
          </Button>
        </ContentHeader>
        {error ? (
          <Text>Falha ao obter os dados dos serviços.</Text>
        ) : (
          <>
            <Box overflowX="auto">
              <Table variant="striped" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th>Nome</Th>
                    <Th>Área de atuação</Th>
                    <Th w="80px" px="0">
                      Excluir
                    </Th>
                  </Tr>
                </Thead>
                <Tbody w="full" gridGap="3">
                  {isLoading
                    ? arrayToRenderSkeleton.map(index => (
                        <Tr key={index}>
                          <Td h="49px">
                            <Skeleton
                              height="28px"
                              maxW="250px"
                              startColor="gray.100"
                              endColor="gray.200"
                            />
                          </Td>
                          <Td h="49px">
                            <Skeleton
                              height="28px"
                              maxW="250px"
                              startColor="gray.100"
                              endColor="gray.200"
                            />
                          </Td>
                          <Td h="49px" px="2">
                            <Skeleton
                              height="32px"
                              width="32px"
                              startColor="gray.100"
                              endColor="gray.200"
                            />
                          </Td>
                        </Tr>
                      ))
                    : data?.services.map(service => (
                        <Tr key={service.id}>
                          <Td>
                            <EditableField
                              defaultValue={service.name}
                              onSubmit={value =>
                                value !== service.name &&
                                handleUpdateService(service.id, {
                                  name: value
                                })
                              }
                            />
                          </Td>
                          <Td>{service.field}</Td>
                          <Td px="2">
                            <IconButton
                              onClick={() => setRemovingData(service)}
                              icon={<Icon as={MdDelete} w="26px" h="26px" />}
                              color="red.400"
                              _hover={{ bg: 'gray.100' }}
                              variant="unstyled"
                              aria-label={`Excluir serviço: ${service?.name}`}
                            />
                          </Td>
                        </Tr>
                      ))}
                </Tbody>
              </Table>
            </Box>
            <Pagination
              currentPage={page}
              onPageChange={page => setPage(page)}
              registerPerPage={registerPerPage}
              onRegisterPerPageChange={registerPerPage => {
                setRegisterPerPage(registerPerPage)
                setPage(1)
              }}
              totalCountOfRegister={totalCountOfRegister}
              siblingsCount={isWideVersion ? 2 : 1}
            />
          </>
        )}
      </Box>
      <ServiceModal isOpen={isOpen} onClose={onClose} onSubmit={remove} />
      <ConfirmationModal
        isOpen={!!removingData}
        title="Excluir serviço?"
        onClose={() => setRemovingData(null)}
        onAccept={handleDeleteService}
      />
    </>
  )
}
