import { extendTheme, ThemeOverride } from '@chakra-ui/react'
//
export const theme = extendTheme({
  breakpoints: {
    '2xl': '96em',
    xl: '86em',
    lg: '62em',
    md: '48em',
    sm: '30em',
    base: '0em'
  },
  shadows: {
    md: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    outline: '0 0 0 3px #EFE838'
  },
  components: {
    Button: {
      variants: {
        solid: ({ colorScheme }) =>
          colorScheme === 'yellow' && {
            bg: 'yellow.300',
            color: 'gray.700',
            _hover: {
              bg: 'yellow.400',
              _disabled: {
                bg: 'yellow.500'
              }
            },
            _active: {
              bg: 'yellow.400'
            }
          }
      }
    },

    Link: {
      baseStyle: {
        _focus: {
          outline: '1.5px solid #EFE838'
        }
      }
    },
    Table: {
      variants: {
        striped: ({ colorScheme }) => ({
          thead: {
            th: {
              borderBottom: 'none'
            }
          },
          tbody: {
            tr: {
              td: {
                borderBottom: 'none',
                py: '1'
              },
              ...(colorScheme === 'gray' && {
                '&:nth-of-type(odd)': {
                  td: {
                    background: 'gray.50'
                  }
                }
              })
            }
          }
        })
      }
    }
  },
  colors: {
    yellow: {
      '400': '#F6E05E',
      '300': '#EFE838'
    },
    green: {
      '500': '#3BD8B2'
    },
    blue: {
      '300': '#58B6F1',
      '900': '#0B4265'
    },
    gray: {
      '900': '#181B23',
      '800': '#1F2029',
      '700': '#383838',
      '600': '#636363',
      '500': '#616480',
      '400': '#797D9A',
      '300': '#9699B0',
      '200': '#B3B5C6',
      '100': '#E6E4E4',
      '50': '#F8F8F8'
    }
  },
  fonts: {
    heading: 'Red Hat Display',
    body: 'Assistant'
  },
  styles: {
    global: {
      body: {
        color: 'gray.700'
      },
      'h1, h2, h3, h4, h5, h6': {
        color: 'gray.600'
      },
      a: {
        _focus: {
          outline: `2px solid #EFE838`
        }
      }
    }
  }
} as ThemeOverride)
