import { ChangeEvent, useEffect, useRef } from 'react'
import { IoAlertCircleOutline } from 'react-icons/io5'

import {
  InputProps as ChakraInputProps,
  Input as ChakraInput,
  theme,
  InputGroup,
  IconButton,
  Icon,
  InputRightElement,
  FormControl,
  FormLabel
} from '@chakra-ui/react'
import { useField } from '@unform/core'

import { masks } from '../../utils/masks'
import { Tooltip } from '../Tooltip'

type Mask = 'price'

type InputProps = ChakraInputProps & {
  name: string
  label?: string
  mask?: Mask
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function Input({ name, label, mask, ...rest }: InputProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null)
  const { defaultValue, error, fieldName, registerField, clearError } =
    useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref: HTMLInputElement) => {
        if (mask === 'price') {
          return Number(
            ref.value
              .replaceAll('.', '')
              .replaceAll(',', '.')
              .replaceAll('R$ ', '')
          )
        }
        return ref.value
      },
      setValue: (ref: HTMLInputElement, value: string | number) => {
        if (mask) {
          ref.value = masks[mask](value)
        } else {
          ref.value = String(value)
        }
      },
      clearValue(ref: HTMLInputElement) {
        ref.value = ''
      }
    })
  }, [fieldName, registerField, mask])

  function handleChange(event: ChangeEvent<HTMLInputElement>): void {
    if (mask) {
      const { value } = event.target
      if (inputRef.current) {
        inputRef.current.value = masks[mask](value)
      }
    }
  }

  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel mb="4">{label}</FormLabel>}
      <InputGroup>
        <ChakraInput
          ref={inputRef}
          defaultValue={defaultValue}
          colorScheme="yellow"
          onFocus={clearError}
          onChange={handleChange}
          _focus={{
            boxShadow: `0 0 0 1px ${theme.colors.yellow['300']}`,
            borderColor: 'yellow.300'
          }}
          {...rest}
        />
        {!!error && (
          <InputRightElement>
            <Tooltip
              hasArrow
              arrowSize={15}
              label={error}
              px="2"
              py="1"
              placement="top-end"
              aria-label="Error tooltip"
            >
              <IconButton
                d="flex"
                icon={<Icon as={IoAlertCircleOutline} />}
                fontSize="22"
                variant="unstyled"
                aria-label="Open error message"
                color="red.500"
              />
            </Tooltip>
          </InputRightElement>
        )}
      </InputGroup>
    </FormControl>
  )
}
