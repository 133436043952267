import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { IoDocumentText } from 'react-icons/all'
import { MdDelete, MdSearch } from 'react-icons/md'

import {
  Box,
  Button,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react'

import { ConfirmationModal } from '../components/ConfirmationModal'
import { ContentHeader } from '../components/ContentHeader'
import { AssessmentsModal } from '../components/Modals/AssessmentsModal'
import { NotFound } from '../components/NotFound'
import { Pagination } from '../components/Pagination'
import { TableSkeleton } from '../components/TableSkeleton'
import { Rating, useRatings } from '../hooks/useRatings'
import { api } from '../services/api'
import { theme } from '../styles/theme'
import { createArray } from '../utils/createArray'
import { formatClientDate } from '../utils/formatDate'

let debounce: NodeJS.Timeout

export function Assessments(): JSX.Element {
  const [page, setPage] = useState(1)
  const [registerPerPage, setRegisterPerPage] = useState(10)
  const [totalCountOfRegister, setTotalCountOfRegister] = useState(0)
  const [inputValue, setInputValue] = useState('')
  const [viewData, setViewData] = useState<Rating | null>(null)
  const [removingData, setRemovingData] = useState<Rating | null>(null)
  const { data, isLoading, isFetching, error, refetch } = useRatings({
    page,
    per_page: registerPerPage,
    filter: inputValue
  })

  const isWideVersion = useBreakpointValue({
    base: false,
    md: true
  })

  const arrayToRenderSkeleton = useMemo(
    () => createArray(registerPerPage),
    [registerPerPage]
  )

  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  })

  useEffect(() => {
    if (data) {
      setTotalCountOfRegister(data.totalCount)
    }
  }, [data, isLoading])

  async function handleDeleteRating(): Promise<void> {
    try {
      if (removingData) {
        await api.delete(`admin/ratings/${removingData.id}`)
        toast({
          status: 'success',
          title: 'Sucesso!',
          description: 'A avaliação foi removida.'
        })
        refetch()
        setRemovingData(null)
      }
    } catch (error) {
      toast({
        status: 'error',
        title: 'Erro!',
        description: 'Um erro ocorreu ao tentar excluir a avaliação.'
      })
    }
  }

  async function handleChangeInput(
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> {
    clearTimeout(debounce)

    debounce = setTimeout(() => {
      if (event.target.value.length <= 3) {
        setInputValue('')
      } else {
        setInputValue(event.target.value)
      }
    }, 1000)
  }

  async function handleExportCsv(): Promise<void> {
    try {
      const { data } = await api.get('admin/ratings/csv')
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8' })
      const csvURL = window.URL.createObjectURL(blob)
      window.open(csvURL)
    } catch (error) {
      toast({
        status: 'error',
        title: 'Erro!',
        description: 'Erro ao exportar!'
      })
    }
  }

  return (
    <>
      <Box w="full" h="min-content" p="8" borderRadius="8" boxShadow="md">
        <ContentHeader
          title="Avaliações"
          isLoading={!isLoading && isFetching}
          direction={isWideVersion ? 'row' : 'column'}
          heading={{ d: 'flex', flexWrap: 'nowrap', alignItems: 'baseline' }}
        >
          <InputGroup maxW="400px">
            <InputLeftElement>
              <Icon as={MdSearch} fontSize="22" />
            </InputLeftElement>
            <Input
              placeholder="Buscar por Nome, OAB, Data, Comentário, Resposta..."
              onChange={handleChangeInput}
              _focus={{
                boxShadow: `0 0 0 1px ${theme.colors.yellow['300']}`,
                borderColor: 'yellow.300'
              }}
            />
            {isLoading && (
              <InputRightElement>
                <Spinner size="sm" />
              </InputRightElement>
            )}
          </InputGroup>
          <Button colorScheme="yellow" minW="120px" onClick={handleExportCsv}>
            Exportar CSV
          </Button>
        </ContentHeader>

        {error ? (
          <Text>Falha ao obter os dados das avaliações.</Text>
        ) : isLoading ? (
          <TableSkeleton skeleton={arrayToRenderSkeleton}>
            <Th>Cliente</Th>
            <Th>Email</Th>
            <Th>Profissional</Th>
            <Th>OAB</Th>
            <Th textAlign="center">Nota</Th>
            <Th textAlign="center">Data</Th>
            <Th textAlign="center" w="100px">
              Detalhes
            </Th>
            <Th textAlign="center" w="80px">
              Excluir
            </Th>
          </TableSkeleton>
        ) : data?.ratings && data.ratings.length > 0 ? (
          <>
            <Box overflowX="auto">
              <Table variant="striped" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th>Cliente</Th>
                    <Th>Email</Th>
                    <Th>Profissional</Th>
                    <Th>OAB</Th>
                    <Th textAlign="center">Nota</Th>
                    <Th textAlign="center">Data</Th>
                    <Th textAlign="center" w="70px">
                      Detalhes
                    </Th>
                    <Th textAlign="center" w="50px">
                      Excluir
                    </Th>
                  </Tr>
                </Thead>
                <Tbody w="full" gridGap="3">
                  {data?.ratings.map(rating => (
                    <Tr key={rating.id}>
                      <Td whiteSpace="nowrap">{rating.customer}</Td>
                      <Td whiteSpace="nowrap">{rating.email}</Td>
                      <Td whiteSpace="nowrap">{rating.lawyer}</Td>
                      <Td whiteSpace="nowrap">{rating.oab}</Td>
                      <Td textAlign="center">{rating.rating}</Td>
                      <Td textAlign="center" whiteSpace="nowrap">
                        {formatClientDate(rating.createdAt)}
                      </Td>
                      <Td px="2" textAlign="center">
                        <IconButton
                          onClick={() => setViewData(rating)}
                          icon={<Icon as={IoDocumentText} w="26px" h="26px" />}
                          color="green.500"
                          _hover={{ bg: 'gray.100' }}
                          variant="unstyled"
                          aria-label={`Ver avaliação`}
                        />
                      </Td>
                      <Td px="2" textAlign="center">
                        <IconButton
                          onClick={() => setRemovingData(rating)}
                          icon={<Icon as={MdDelete} w="26px" h="26px" />}
                          color="red.400"
                          _hover={{ bg: 'gray.100' }}
                          variant="unstyled"
                          aria-label={`Excluir avaliação: ${rating?.customer}`}
                        />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
            <Pagination
              currentPage={page}
              onPageChange={page => setPage(page)}
              registerPerPage={registerPerPage}
              onRegisterPerPageChange={registerPerPage => {
                setRegisterPerPage(registerPerPage)
                setPage(1)
              }}
              totalCountOfRegister={totalCountOfRegister}
              siblingsCount={isWideVersion ? 2 : 1}
            />
          </>
        ) : (
          <NotFound />
        )}
      </Box>
      {viewData && (
        <AssessmentsModal
          isOpen={!!viewData}
          onClose={() => setViewData(null)}
          id={viewData.id}
        />
      )}
      <ConfirmationModal
        isOpen={!!removingData}
        title="Excluir avaliação?"
        onClose={() => setRemovingData(null)}
        onAccept={handleDeleteRating}
      />
    </>
  )
}
