import { useRef, useState } from 'react'
import { Link as ReactLink } from 'react-router-dom'

import { Button, Divider, Heading, Link } from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import * as yup from 'yup'

import { Form } from '../components/Form/Form'
import { Input } from '../components/Form/Input'
import { useAuth } from '../hooks/useAuth'
import { formatYupError } from '../utils/formatYupError'

type FormData = {
  email: string
  password: string
}

const loginFormSchema = yup.object({
  email: yup
    .string()
    .email('E-mail Inválido.')
    .required('E-mail é obrigatório.'),
  password: yup.string().required('Senha é obrigatória.')
})

export function SignIn(): JSX.Element {
  const formRef = useRef<FormHandles>(null)
  const { signIn } = useAuth()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit: SubmitHandler<FormData> = async data => {
    setIsLoading(true)
    try {
      await loginFormSchema.validate(data, { abortEarly: false })
      await signIn(data)
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      w="full"
      maxW="400"
      pt="5"
      pb="7"
      px="9"
      borderRadius="4"
      boxShadow="md"
      d="flex"
      flexDirection="column"
    >
      <Heading fontSize="xl">Acessar conta</Heading>
      <Divider my="4" />
      <Input
        name="email"
        label="E-mail"
        placeholder="Informe seu e-mail"
        mb="4"
      />
      <Input
        name="password"
        label="Senha"
        placeholder="Informe sua senha"
        type="password"
        mb="3"
      />
      <Link as={ReactLink} to="forgot" ml="auto" textDecoration="underline">
        Esqueci minha senha.
      </Link>
      <Button
        type="submit"
        colorScheme="yellow"
        w="full"
        h="48px"
        mt="5"
        fontSize="lg"
        isLoading={isLoading}
      >
        Entrar
      </Button>
    </Form>
  )
}
