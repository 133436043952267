import { useContext } from 'react'

import {
  SideBarDrawerContext,
  SidebarDrawerContextData
} from '../contexts/SideBarDrawerContext'

export function useSidebarDrawer(): SidebarDrawerContextData {
  const data = useContext(SideBarDrawerContext)
  return data
}
