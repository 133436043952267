import { useRef, useState } from 'react'

import { Box, Flex, FormLabel, Text, useToast } from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import axios from 'axios'
import * as yup from 'yup'

import { api } from '../services/api'
import { formatYupError } from '../utils/formatYupError'
import { masks } from '../utils/masks'
import { Form } from './Form/Form'
import { Input } from './Form/Input'
import { Switch } from './Form/Switch'
import { FormContainer } from './FormContainer'

export type PlansProps = {
  id: number
  name: string
  price: number | string
  free: string
  active: boolean
  slug: 'free' | 'silver' | 'gold'
}

type PlanContainerProps = {
  plan: PlansProps
  refresh: () => void
}

const planFormSchema = yup.object({
  name: yup.string().required('Nome é obrigatório!.')
})

export function PlanContainer({
  plan,
  refresh
}: PlanContainerProps): JSX.Element {
  const [planUuid, setPlanUuid] = useState<number>()
  const formRef = useRef<FormHandles>(null)
  const toast = useToast({
    position: 'top-right',
    duration: 5000,
    isClosable: true
  })

  const handleSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
    try {
      await planFormSchema.validate(data, { abortEarly: false })
      await api.put(`admin/plans/${planUuid}`, data)
      reset()
      refresh()
      toast({
        status: 'success',
        title: 'Sucesso!',
        description: 'Atualizado com sucesso!'
      })
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      } else if (axios.isAxiosError(error)) {
        toast({
          status: 'error',
          title: 'Erro!',
          description: 'Ocorreu um erro ao tentar salvar as alterações.'
        })
      }
    }
  }

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      key={plan.id}
      initialData={{
        active: plan.active
      }}
    >
      <FormContainer
        headerText={plan.name}
        buttonText={'Salvar Alterações'}
        handleClick={() => setPlanUuid(plan.id)}
      >
        <Input
          name={'name'}
          placeholder={'Informe o nome do plano'}
          label={'Nome do Plano (obrigatório)'}
          mb={6}
        />
        <Box w={'full'} mb={6}>
          <FormLabel>Preço do Plano</FormLabel>
          <Flex
            border={'1px solid #E2E8F0'}
            borderRadius={'6px'}
            px={'16px'}
            h={'40px'}
            alignItems={'center'}
            bgColor={plan.slug === 'free' ? '#E2E8F0' : 'white'}
          >
            <Text opacity={'36%'}>
              {plan.slug === 'free' ? 'Gratuito' : masks.price(plan.price)}
            </Text>
          </Flex>
        </Box>

        <Switch
          isDisabled={plan.slug === 'free'}
          label={'Aberto para novos cadastros'}
          name={'active'}
          mb={6}
        />
      </FormContainer>
    </Form>
  )
}
