import {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useCallback
} from 'react'
import { useHistory } from 'react-router-dom'

import { useToast } from '@chakra-ui/react'
import axios from 'axios'

import { api } from '../services/api'

type AuthContextProviderProps = {
  children: ReactNode
}

type User = {
  id: number
  fullName: string
  email: string
  avatar: string
  token: string
  master: boolean
}

type SignInData = {
  email: string
  password: string
}

export type AuthContextType = {
  user: User | null
  isAuthenticated: boolean
  loading: boolean
  signIn: (signInData: SignInData) => Promise<void>
  signOut: () => void
  setNewInformation: () => Promise<void>
}

export const AuthContext = createContext({} as AuthContextType)

export function AuthContextProvider({
  children
}: AuthContextProviderProps): JSX.Element {
  const [loading, setLoading] = useState<boolean>(true)
  const [user, setUser] = useState<User | null>(null)
  const isAuthenticated = !!user

  const history = useHistory()
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  const recoverUserInformation = useCallback(async () => {
    const { data } = await api.get<User>('admin/settings/profile')
    return data
  }, [])

  const setNewInformation = useCallback(async () => {
    const newInformation = await recoverUserInformation()
    if (newInformation) {
      setUser(newInformation)
    }
  }, [recoverUserInformation])

  const signOut = useCallback(
    (redirect = true) => {
      setUser(null)
      delete api.defaults.headers.Authorization
      localStorage.removeItem('@lawy:token')
      if (redirect) history.push('/')
    },
    [history]
  )

  useEffect(() => {
    const token = localStorage.getItem('@lawy:token')
    if (token) {
      const parsedToken = JSON.parse(token)
      api.defaults.headers.Authorization = `Bearer ${parsedToken}`
      recoverUserInformation()
        .then(user => setUser(user))
        .catch(() =>
          toast({
            title: 'Erro',
            description: 'Erro ao tentar recuperar dados do usuario.',
            status: 'error'
          })
        )
        .finally(() => {
          setLoading(false)
        })
    } else {
      signOut(false)
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recoverUserInformation, signOut])

  async function signIn({ email, password }: SignInData): Promise<void> {
    try {
      const { data } = await api.post<User>('/admin/auth', { email, password })
      api.defaults.headers.Authorization = `Bearer ${data.token}`
      localStorage.setItem('@lawy:token', JSON.stringify(data.token))
      setUser(data)
      history.push('/home')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast({
          title: 'Erro ao logar',
          description: 'Email ou senha inválidos',
          status: 'error'
        })
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        signIn,
        setNewInformation,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
