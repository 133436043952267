import { createContext, ReactNode } from 'react'

import { useDisclosure, UseDisclosureReturn } from '@chakra-ui/hooks'

export type SidebarDrawerContextData = Pick<
  UseDisclosureReturn,
  'isOpen' | 'onOpen' | 'onClose'
>

interface SidebarDrawerProviderProps {
  children: ReactNode
}

export const SideBarDrawerContext = createContext(
  {} as SidebarDrawerContextData
)

export const SideBarDrawerProvider = ({
  children
}: SidebarDrawerProviderProps): JSX.Element => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <SideBarDrawerContext.Provider value={{ isOpen, onClose, onOpen }}>
      {children}
    </SideBarDrawerContext.Provider>
  )
}
