import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import { AxiosRequestConfig } from 'axios'

import { api } from '../services/api'
import formatCpfCnpj from '../utils/masks'

export type Lawyer = {
  id: string
  fullName: string
  email: string
  city: string
  plan: string
  clicks: number
  visits: number
  rating: number
  cpfCnpj: string
  oab: string
}

type GetLawyersProps = {
  page?: number
  per_page?: number
  filter?: string
}

export type GetLawyersResponse = {
  totalCount: number
  lawyers: Lawyer[]
}

export async function getLawyers(
  { page, per_page, filter }: GetLawyersProps = {},
  config?: AxiosRequestConfig
): Promise<GetLawyersResponse> {
  const { data, headers } = await api.get<Lawyer[]>('/admin/lawyers', {
    params: {
      page: page,
      limit: per_page,
      ...(filter ? { filter } : {})
    },
    ...config
  })
  const totalCount = Number(headers['x-total-count'] ?? 0)

  return {
    lawyers:
      data.map(lawyer => ({
        ...lawyer,
        cpfCnpj: formatCpfCnpj(lawyer.cpfCnpj)
      })) ?? [],
    totalCount
  }
}

export function useLawyers(
  { page, per_page, filter }: GetLawyersProps = {},
  options?: UseQueryOptions<GetLawyersResponse>
): UseQueryResult<GetLawyersResponse> {
  return useQuery<GetLawyersResponse>(
    ['lawyers', page, per_page, filter],
    () => getLawyers({ page, per_page, filter }),
    {
      staleTime: 1000 * 10, // Ten seconds
      ...options
    }
  )
}
