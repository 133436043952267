import { ReactNode } from 'react'

import { Skeleton, Table, Tbody, Td, Thead, Tr } from '@chakra-ui/react'

type TableSkeletonProps = {
  skeleton: number[]
  children: ReactNode
}

export function TableSkeleton({
  skeleton,
  children
}: TableSkeletonProps): JSX.Element {
  return (
    <Table variant="striped" colorScheme="gray">
      <Thead>
        <Tr>{children}</Tr>
      </Thead>
      <Tbody w="full" gridGap="3">
        {skeleton.map(index => (
          <Tr key={index}>
            <Td h="49px">
              <Skeleton
                height="28px"
                maxW="250px"
                startColor="gray.100"
                endColor="gray.200"
              />
            </Td>
            <Td h="49px">
              <Skeleton
                height="28px"
                maxW="250px"
                startColor="gray.100"
                endColor="gray.200"
              />
            </Td>
            <Td h="49px">
              <Skeleton
                height="28px"
                maxW="250px"
                startColor="gray.100"
                endColor="gray.200"
              />
            </Td>
            <Td h="49px">
              <Skeleton
                height="28px"
                maxW="250px"
                startColor="gray.100"
                endColor="gray.200"
              />
            </Td>
            <Td h="49px">
              <Skeleton
                height="28px"
                maxW="250px"
                startColor="gray.100"
                endColor="gray.200"
              />
            </Td>
            <Td h="49px">
              <Skeleton
                height="28px"
                maxW="250px"
                startColor="gray.100"
                endColor="gray.200"
              />
            </Td>
            <Td h="49px">
              <Skeleton
                height="28px"
                maxW="250px"
                startColor="gray.100"
                endColor="gray.200"
              />
            </Td>
            <Td h="49px">
              <Skeleton
                height="28px"
                maxW="250px"
                startColor="gray.100"
                endColor="gray.200"
              />
            </Td>
            <Td h="49px" px="2">
              <Skeleton
                height="32px"
                width="32px"
                startColor="gray.100"
                endColor="gray.200"
              />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}
