import { useRef, useState } from 'react'
import { Link as ReactLink, useHistory } from 'react-router-dom'

import { Button, Divider, Heading, Link, useToast } from '@chakra-ui/react'
import { FormHandles, SubmitHandler } from '@unform/core'
import axios from 'axios'
import * as yup from 'yup'

import { Form } from '../components/Form/Form'
import { Input } from '../components/Form/Input'
import { api } from '../services/api'
import { formatYupError } from '../utils/formatYupError'

type FormData = {
  email: string
}

const forgotFormSchema = yup.object({
  email: yup.string().email('E-mail Inválido').required('E-mail é obrigatório.')
})

export function Forgot(): JSX.Element {
  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const toast = useToast({
    duration: 5000,
    isClosable: true,
    position: 'top-right'
  })

  const handleSubmit: SubmitHandler<FormData> = async data => {
    setIsLoading(true)
    try {
      await forgotFormSchema.validate(data, { abortEarly: false })
      await api.post('/admin/password', data)
      toast({
        title: 'Sucesso!',
        description: 'Email enviado com sucesso!',
        status: 'success'
      })
      history.push('/')
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        formRef.current?.setErrors(formatYupError(error))
      }
      if (axios.isAxiosError(error)) {
        toast({
          title: 'Erro ao recuperar senha',
          description: 'Email inválido',
          status: 'error'
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      w="full"
      maxW="400"
      pt="5"
      pb="7"
      px="9"
      boxShadow="md"
      borderRadius="4"
      d="flex"
      flexDirection="column"
    >
      <Heading as="h1" fontSize="xl">
        Esqueceu sua senha?
      </Heading>
      <Divider my="4" />
      <Input name="email" label="E-mail" placeholder="Informe seu e-mail" />

      <Button
        type="submit"
        colorScheme="yellow"
        w="full"
        h="48px"
        my="6"
        fontSize="lg"
        isLoading={isLoading}
      >
        Recuperar senha
      </Button>
      <Link as={ReactLink} to="/" d="block" textDecoration="underline">
        Voltar
      </Link>
    </Form>
  )
}
