import * as yup from 'yup'

export const createFormSchema = yup.object({
  fullName: yup.string().required('Nome é obrigatório!.'),
  email: yup
    .string()
    .email('E-mail Inválido.')
    .required('E-mail é obrigatório.')
})

export const updateFormSchema = yup.object({
  email: yup
    .string()
    .email('E-mail Inválido.')
    .required('E-mail é obrigatório.'),
  fullName: yup.string().required('Nome é obrigatório!.')
})

export const passwordFormSchema = yup.object({
  password: yup.string().required('Senha atual é obrigatória.'),
  newPassword: yup.string().required('Nova senha é obrigatória.'),
  newPasswordConfirm: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Senha precisa ser igual')
    .required('Confirmação de senha é obrigatória.')
})
