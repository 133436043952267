import Chart from 'react-apexcharts'

import { Flex, Text } from '@chakra-ui/react'

import { ChartProps, configOptions } from './config'

export type GraphicProps = {
  y: number[] | string[]
  x: number[] | string[]
}

type ChartViewProps = {
  value: GraphicProps | undefined
  isLine?: boolean
  title: string
}

export default function ChartView({
  value,
  title,
  isLine
}: ChartViewProps): JSX.Element {
  const graphicValues: ChartProps = {
    options: {
      ...configOptions,
      xaxis: {
        categories: value?.x || [''],
        labels: {
          show: !!isLine
        }
      }
    },
    series: [
      {
        name: isLine ? 'Valor' : 'Quantidade',
        data: value?.y || ['']
      }
    ]
  }

  return (
    <Flex
      h="min-content"
      w="full"
      borderRadius="6"
      boxShadow="md"
      p="5"
      d="column"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
    >
      <Text fontSize="14px" marginBottom="10px">
        {title}
      </Text>
      <Chart
        options={graphicValues?.options}
        series={graphicValues?.series}
        type={isLine ? 'line' : 'bar'}
        height="215"
        width="100%"
      />
    </Flex>
  )
}
