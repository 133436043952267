import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'

import { ChakraProvider } from '@chakra-ui/react'

import { AuthContextProvider } from './contexts/AuthContext'
import { Routes } from './routes'
import { queryClient } from './services/queryClient'
import { theme } from './styles/theme'

export function App(): JSX.Element {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthContextProvider>
            <Routes />
          </AuthContextProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ChakraProvider>
  )
}
